
import './Chip.css';
import _003ETH from './assets/01BNB.png';
import _006ETH from './assets/02BNB.png';
import _015ETH from './assets/03BNB.png';
import _03ETH from './assets/05BNB.png';
import _06ETH from './assets/1BNB.png';
import { forwardRef } from 'react';

function Chip(props, ref) {

    const {amount, chipList} = props;

    function identifyChipImage() {
        let chipImage = _003ETH;
        chipList.forEach((element) => {
            if(amount >= element.value) {
                chipImage = element.image;
            }
        });
        return chipImage;
    }

    return amount > 0 ? <div className='container'>    
    <div className='imgContainer'>
        <img src={identifyChipImage()} alt="Chip" className="chipImg"/>
    </div>
        <div className="centered">{amount}</div> 
    </div> : <div></div>
}

export default forwardRef(Chip);