import { createWeb3Modal } from '@web3modal/wagmi/react'

import { http, createConfig, WagmiProvider } from 'wagmi'
import { defineChain } from 'viem'
import { walletConnect, injected } from 'wagmi/connectors'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '8e0adc0fdd2233e1cd94a7774cf88743'

// 2. Create wagmiConfig
const metadata = {
  name: 'RouletteOnBlast',
  description: 'Play Onchain Roulette: Fast, fair, and fun gaming on the Blast Network!',
  url: 'https://rouletteonblast.io/', // origin must match your domain & subdomain
  // url: 'https://localhost:3000/', // origin must match your domain & subdomain
  icons: ['https://rouletteonblast.io/static/media/logo.c8edb9ed.png']
}


export const mainnet = defineChain({
    id: 81457,
    name: 'Blast Mainnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://rpc.blast.io/'] },
    },
  })

export const sepolia = defineChain({
    id: 168587773,
    name: 'Blast Sepolia',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://sepolia.blast.io'] },
    },
    blockExplorers: {
      default: { name: 'Etherscan', url: 'https://testnet.blastscan.io/' },
    },
})
  



//TODO CREATE CHAINS
export const config = createConfig({
  chains: [mainnet, sepolia],
  // chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http()
  },
  connectors: [
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
  ]
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
})

export default function ContextProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}